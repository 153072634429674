import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Test Week starts today!`}</em></p>
    <p>{`Back Squat 1-1-1-1-1 to a 1RM`}</p>
    <p>{`Glute Ham Raise 3-3-3-3-3, weighted`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`15-Back Squats (185/125)`}</p>
    <p>{`20-Burpees`}</p>
    <p><strong parentName="p">{`*`}{`CrossFit Open WOD 17.3 will be released tonight at 8:00pm!`}</strong></p>
    <p><strong parentName="p">{`*`}{`This Saturday is our free workout so bring a friend! 8:00 & 9:00am
at The Ville, 10:00 & 11:00am at East.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      